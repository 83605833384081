.icon {
    width: 100px;
    height: 100px;
    margin: 20px;
    display:inline-block;

}
.loader{
    display: flex;
    align-items: center;
    justify-content: center;
}