.absolute-alert{
    position: fixed !important;
    z-index: 2020;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
}

.alert-success{
    background-color: var(--success-message-bg-color);
}