.loginPage{
    display: flex;
}
.leftPanel{
    width: 50%;
    background-color: var(--primary-alt-color);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-text-alt-color);
    font-size: large;

}

.rightPanel{
    width: 50%;
    justify-content: center;
}

.leftPanelOverlayImg{
    position:absolute;
    left:0;
    bottom:0;
    width: 50vw;
    mix-blend-mode: color-burn;
}
.leftPanelMessage{
    padding: 20px;
}


@media screen and (max-width: 960px) {
    .leftPanel{
        display: none;
    } 
    .rightPanel{
        width: 100%;
    }
  }