.SingleDatePicker_picker, .DateInput_fang, .DateRangePicker_picker{
    z-index: 10 !important;
}
.SingleDatePickerInput__withBorder, .DateRangePickerInput__withBorder{
    // border: 0 none !important;
    // border-bottom:solid 1px #939283 !important;
    border-color: #939283 !important;
    border-radius:4px !important;
}
.DateInput_input{
    padding: 1px 9px 11px !important;
    height: 36px;
    font-weight: unset !important;
    font-size: 1rem !important;
}
.DateInput_input, .DateInput, .SingleDatePickerInput, .DateRangePickerInput{
    background-color: transparent !important;
}
.DateInput_input__focused{
    color: var(--primary) !important;
    border-bottom-color: var(--primary) !important;
    background-color: var(--input-active) !important;
}

.DateRangePickerInput__block {
    display: flex !important;
    justify-content: space-between !important;
}

.DateRangePickerInput_arrow{
    line-height: 2.5;
}

.DateRangePicker_picker{
    box-shadow: 4px 4px 8px rgba(0,0,0,.2);
}
.DateInput_input{
    padding: 11px !important;
}